/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const EssaysPage = ({ data, location }) => {
  const { setBackground } = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;
  const essays = [];

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    essays.push(node);
  });

  useEffect(() => {
    setBackground(`white`);
  }, []);

  //

  essays.sort((a, b) => {
    const priorityA = a.frontmatter.priority;
    const priorityB = b.frontmatter.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="essays-page w-full relative pt-24">
        <ul className="essays-page__grid grid flex flex-wrap pt-8 xs:pt-16">
          {essays.map((essay, essayIndex) => {
            return (
              <li
                key={essay.frontmatter.title}
                className="essays-page__essay grid-end-4 xs:grid-end-12 relative block mb-12 mr-4 xs:mr-0"
              >
                <Link
                  to={essay.fields.slug}
                  className="animation-appear-right-slow w-full h-full relative flex flex-col items-stretch"
                  onMouseEnter={() =>
                    setBackground(essay.frontmatter.backgroundColor)
                  }
                  onMouseLeave={() => setBackground(`white`)}
                  style={{ animationDelay: `${essayIndex * 100 + 300}ms` }}
                >
                  <figure className="w-full relative">
                    <Img
                      className="w-full block"
                      fluid={essay.frontmatter.image.childImageSharp.fluid}
                      alt={essay.frontmatter.title}
                    />
                  </figure>

                  <div className="relative">
                    <h2 className="essays-page__essay__title w-full mt-2 mx-auto underline caption caption--serif text-left">
                      {essay.frontmatter.title}
                    </h2>

                    <h3 className="essays-page__essay__title w-full mx-auto caption uppercase text-left">
                      {essay.frontmatter.author}
                    </h3>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </Layout>
    </>
  );
};

export default EssaysPage;

export const query = graphql`
  query EssaysPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "essay-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            author
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            backgroundColor
          }
        }
      }
    }
  }
`;
